import moment from 'moment/moment';
import Range from 'rc-slider';
import { HandleProps } from 'rc-slider/lib/Handles/Handle';
import { MarkObj } from 'rc-slider/lib/Marks';
import { ReactElement, ReactNode, useMemo } from 'react';

import { PointDataFrameDef } from '../../../model/definitions/PointDataFrameDef';

interface Props {
  timestamps: PointDataFrameDef[];
  defaultValue: PointDataFrameDef[] | undefined;
  isObserved: boolean;
  onValueChange: (val: PointDataFrameDef[]) => void;
}

const dotStyle = {
  width: 0.1,
  borderRadius: 0,
  border: 0,
};

export const PointTimestampsRange = ({
  timestamps,
  defaultValue,
  isObserved,
  onValueChange,
}: Props) => {
  const defaultValueIndexes = useMemo(() => {
    if (defaultValue && defaultValue.length > 1) {
      const result: number[] = [];
      timestamps.forEach((timestamp, index) => {
        if (defaultValue[0].startDate === timestamp.startDate) {
          result.push(index);
        }
        if (defaultValue[defaultValue.length - 1].startDate === timestamp.startDate) {
          result.push(index);
        }
      });
      return result;
    }
    const lastIndex = timestamps.length - 1;
    return isObserved ? [lastIndex - 1, lastIndex] : [0, 1];
  }, [defaultValue, timestamps, isObserved]);

  const sliderMarks = useMemo(() => {
    const marks: Record<number, MarkObj> = {};
    timestamps
      .sort((a, b) => {
        const dateA = new Date(a.startDate);
        const dateB = new Date(b.startDate);
        return dateA.getTime() - dateB.getTime(); // Changed to dateA - dateB
      })
      .forEach((t, i) => {
        marks[i] = {
          label: moment(new Date(t.dateString)).format('dddd DD-MMM-YYYY HH:MM'),
          style: {
            top: -40,
            display: i === 0 || i === timestamps.length - 1 ? 'block' : 'none',
            padding: '2px 0',
            borderRadius: 0,
          },
        };
      });
    return marks;
  }, [timestamps]);

  const sliderHandle = (e: ReactElement<HandleProps>, key: number, value: ReactNode) => {
    return {
      ...e,
      props: {
        ...e.props,
        style: {
          ...e.props.style,
          top: 20,
          boxShadow: 'none',
          width: 20,
          height: 20,
          borderRadius: 0,
          backgroundColor: '#0054ff',
          transform: 'translateX(-50%) rotate(-45deg)',
        },
        children: [
          <div
            key={key}
            style={{
              top: -5,
              transform: 'translateX(-50%) rotate(45deg)',
              position: 'relative',
              width: 'fit-content',
              backgroundColor: '#0054ff',
              color: '#ffffff',
              padding: 2,
              borderRadius: 3,
            }}
          >
            {value}
          </div>,
        ],
      },
    };
  };

  return (
    <div id="FramesRange">
      <Range
        defaultValue={defaultValueIndexes}
        draggableTrack
        range={true}
        className="frames-range"
        min={0}
        max={timestamps.length - 1}
        marks={sliderMarks}
        dotStyle={dotStyle}
        handleRender={(props, origin) =>
          sliderHandle(props, origin.index, sliderMarks[origin.value]?.label)
        }
        onChange={(e: number | number[]) => {
          if (Array.isArray(e)) {
            // console.log(e);
            onValueChange(
              e[1] - e[0] >= 1
                ? timestamps.slice(e[0], e[1] + 1)
                : timestamps.slice(e[0], e[0] + 1),
            );
          }
        }}
      />
    </div>
  );
};
